import React from 'react';
import { Link } from 'react-router-dom';
// import logo from './logo.png';

const HomePage = () => {
  const logo = 'https://anglican.listenu.cn/static/demo/anglican-logo.png';
  return (
    <div className="home-container">
      <img src={logo} alt="安里甘艺术中心Logo" className="home-logo" />
      <h1>安里甘艺术中心问答</h1>
      <Link to="/questions?category=1" className="left-section">
        <h2>关于建筑、空间和历史</h2>
      </Link>
      <Link to="/questions?category=2" className="right-section">
        <h2>关于运维</h2>
      </Link>
    </div>
  );
};

export default HomePage;