// QuestionsPage.js
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { get } from './utils/request';

const QuestionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('category');

  const logo = 'https://anglican.listenu.cn/static/demo/anglican-logo.png';

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    get('getQuestionList', {
      categoryId: category,
    })
      .then(response => {
        setQuestions(response.data);
      })
      .catch(error => {
        console.log('error', error);
      });
  }, []);

  // 跳转首页
  const navigate = useNavigate();
  const goHome = () => { navigate('/'); }

  const IconHome = () => (
    <i className="iconfont icon-back"></i> // 使用定义好的CSS类
  );

  return (
    <div className="questions-page">
			<div className="section">
        <div className='section-header'>
          {(category === '1') && <div className="section-title">
            <img src={logo} alt="安里甘艺术中心Logo" className="section-logo" onClick={goHome} />
            <span>关于建筑、空间和历史</span>
          </div>}
          {(category === '2') && <div className="section-title">
            <img src={logo} alt="安里甘艺术中心Logo" className="section-logo" onClick={goHome} />
            <span>关于运维</span>
          </div>}

          <div className="section-back" onClick={goHome}>
            <IconHome />
            <span>返回首页</span>
          </div>
        </div>
        <div className="questions">
            {(questions.length > 0) ?
              (questions.map(question => (
              <Link to={`/questions/` + question.id} className="question" key={question.id}>
                  Q: {question.question}
              </Link>
              ))
            ) : (<p>加载中...</p>)}
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage;