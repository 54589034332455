// App.js
import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  createBrowserRouter,
  // createRoutesFromElements,
  // Route,
  RouterProvider,
} from "react-router-dom";
import HomePage from './HomePage';
import QuestionsPage from './QuestionsPage';
// import questionsData from './questionsData';
import QuestionDetailPage from './QuestionDetailPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/questions",
    element: <QuestionsPage />,
  },
  {
    path: "/questions/:questionId",
    element: <QuestionDetailPage />,
  }
]);


const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
