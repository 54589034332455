// QuestionDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from './utils/request';


const QuestionDetailPage = () => {
  const { questionId } = useParams(); // 从URL参数中获取问题ID
  const [question, setQuestion] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const getQuestionAnswer = async () => {
    try {
      const response = await get('getQuestionAnswer', { questionId });
      setQuestion(response.data);

      setBreadcrumbs(
        [
          { text: '首页', link: '/' },
          { text: response.data.question, link: `/questions/${response.data.id}`}
        ]
      );

    } catch (error) {
      console.log('getQuestionAnswer error', error);
    }
  };

  useEffect(() => {
    setBreadcrumbs(
      [{ text: '首页', link: '/' }]
    );
    getQuestionAnswer();
  }, []);

  const navigate = useNavigate();
  const goBack = () => { navigate(-1); }
  const IconHome = () => (
    <i className="iconfont icon-back"></i> // 使用定义好的CSS类
  );

  return (
    <div className="question-detail-page">
      <div className="question-detail-header">
        <div className="section-back" onClick={goBack}>
          <IconHome />
          <span>返回</span>
        </div>
      </div>
      <nav className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span> > </span>}
            {index < breadcrumbs.length - 1 ? (
              <a href={breadcrumb.link}>{breadcrumb.text}</a>
            ) : (
              <span className="current">{breadcrumb.text}</span> 
            )}
          </span>
        ))}
      </nav>

      {question ? (
        <>
          <h1 className="question-title">{question.question}</h1>
          <div className="answer-content" dangerouslySetInnerHTML={{ __html: question.answer }} />
        </>
      ) : (
        <p className="not-found">问题未找到</p>
      )}
    </div>
  );
};

export default QuestionDetailPage;