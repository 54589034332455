// request.js
import axios from 'axios';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // API的base_url
  timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，比如设置token
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken();
    // }
    return config;
  },
  error => {
    // 对请求错误做些什么
    console.error('请求错误:', error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    /**
     * 可以在这里对响应数据做点什么
     * 比如，根据状态码判断请求是否成功
     */
    const res = response.data;
    if (response.status !== 200) {
      console.error('请求失败:', res);
      return Promise.reject(new Error('请求失败'));
    } else {
      return res;
    }
  },
  error => {
    console.error('响应错误:', error); // for debug
    return Promise.reject(error);
  }
);

// 改进后的get和post请求封装
export function get(url, params = {}, config = {}) {
  return service.get(url, { params, ...config });
}

export function post(url, data = {}, config = {}) {
  return service.post(url, data, config);
}

// 其他类型的请求也可以按照这种方式进行封装
